import React from 'react'

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  return (
    <>
      {children}
    </>
  )
}
export default DefaultLayout;
