import Register from '../views/Register';
import DefaultLayout from '../layouts/Default';

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Register
  }
]