import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as Env from './config/back4apps'
import Parse from 'parse';
import routes from './utils/routes'

Parse.serverURL = Env.SERVER_URL;
Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY)

export default () => {

  return ( 
    <Router basename={'/register/'}>
      {routes.map((route, index) => {
        return (
          <Route key={index} path={route.path} exact={route.exact} component={props => {
            return (
              <route.layout {...props}>
                <route.component {...props} />
              </route.layout>
            );
          }}
          />
        );
      })}
    </Router>
  )

};
