import React, { useState, useEffect } from 'react';
import './Register.css';
import Parse from 'parse'; //Import parse

import Loader from '../components/loader/Loader';
import SuccessComponent from '../components/alert/SuccessComponent';
import AlertComponent from '../components/alert/AlertComponent';

export default function Register() {

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    birth_year: '',
    membership_year: '',
    gender: '',
    merital_status: '',
    spouse_member: 'undefined',
    dependents: '',
    phone_number: '',
    address: '',
    department: '',
    email: '',
    password: '',
  });

  const departmentList = [
    { id: "none", name: "None" },
    { id: "adoration", name: " Adoration" },
    { id: "advisory", name: "Advisory" },
    { id: "bible_class", name: "Bible Class" },
    { id: "bible_college", name: "Bible College" },
    { id: "carees_department", name: "Careers Department" },
    { id: "childrens_church", name: "Children's Church" },
    { id: "creative_arts", name: "Creative Arts" },
    { id: "dance", name: "Dance" },
    { id: "defence", name: "Defence" },
    { id: "evangelism_and_mission", name: "Evangelism And Mission" },
    { id: "health_safety_and_security", name: "Health Safety And Security" },
    { id: "helping_hand", name: "Helping Hand" },
    { id: "home_affiars", name: "Home Affairs" },
    { id: "home_fellowship", name: "Home Fellowship" },
    { id: "ladies_fellowship", name: "Home Fellowship" },
    { id: "marriage_workshop", name: "Marriage Workshop" },
    { id: "office_and_administration", name: "Office Addministration" },
    { id: "pastrol", name: "Pastrol" },
    { id: "public_ralations", name: "Public Relations" },
    { id: "senior_citizen", name: "Senior Citizen" },
    { id: "sports_and_recreations", name: "Spots And Recreations" },
    { id: "teens_church", name: "Teen's Church" },
    { id: "ushering", name: "Ushering" },
    { id: "youth_and_young_adult", name: "Youth And Young Adult" },
  ]

  const [error, setError] = useState({ message: '' });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ message: '' });
  var expanded = false;

  const [department, setDepartment] = useState(null);
  const [opened, setOpend] = useState(false);

  const showCheckboxes = (event) => {

    if (event.target.id === 'selectBox' || 'overSelect') {

      var checkboxes = document.getElementById("checkboxes");
      if (!expanded) {
        checkboxes.style.display = "block";
        expanded = (true);
        setOpend(true)
      }

      else {
        checkboxes.style.display = "none";
        expanded = (false);
        setOpend(false)
      }
    }

  }

  const chooseDepartment = (event) => {
    setDepartment(state => ({
      ...state,
      [event.target.id]: event.target.checked
    }));

    setState(state => ({
      ...state, department: {
        ...state.department,
        [event.target.id]: event.target.checked
      }
    }));
  }

  useEffect(() => {

  }, []);

  const handleChange = e => {
    e.preventDefault();
    let { name, value } = e.target;
    setState(state => ({ ...state, [name]: value }));
  }

  const handleSubmit = e => {
    e.preventDefault();

    localStorage.removeItem("tft_access_token");
    Parse.User.logOut();

    if (state.gender === '') {
      return alert('Please Choose Your Gender!');
    }

    else if (state.merital_status === '') {
      return alert('Please Choose Merital Status!');
    }

    else if (state.dependents === '') {
      return alert('Please Check if you have dependents under 18 who attend TFT (Yes or No)')
    }

    else if (state.department === '') {
      return alert('Please Choose Department')
    }

    else {
      setLoading(true);
      setSuccess({ message: '' })
      setError({ message: '' });

      register(state).then(res => {
        console.log(res);
        setLoading(false);
        setSuccess({ message: 'Thank you. You\'re registered! Check your email to confirm, NB: check your spam folder' })
        return res;
      })
        .catch(err => {
          console.log(err);
          setLoading(false);
          setError({ message: err.message });
        });
    }
  }

  const register = (userData) => {
    return new Promise((resolve, reject) => {
      const User = new Parse.User();
      let profilePhotoFile;

      if (userData.profilePhoto) {
        profilePhotoFile = new Parse.File(userData.profilePhoto.name, userData.profilePhoto);
        User.set("profilePhoto", profilePhotoFile)
        console.log(profilePhotoFile)
      }

      User.set("first_name", userData.first_name)
      User.set("last_name", userData.last_name)
      User.set("username", userData.email)

      User.set("password", userData.password)
      User.set("email", userData.email);
      User.set("birth_year", userData.birth_year);

      User.set("membership_year", userData.membership_year);
      User.set("gender", userData.gender);
      User.set("merital_status", userData.merital_status);

      User.set("phone_number", userData.phone_number);
      User.set("address", userData.address);
      User.set("department", userData.department);
      User.set("dependents", userData.dependents);
      User.set("dependent_list", userData.dependent_list);
      User.set("spouse_member", userData.spouse_member);

      User.signUp().then(res => {
        console.log(JSON.parse(JSON.stringify(res)))
        console.log(res)
        resolve(JSON.parse(JSON.stringify(res)))
      })
        .catch(error => {
          console.log(error)
          reject(JSON.parse(JSON.stringify(error)))
        })
    });
  }

  return (
    <>
      {/* <div id="preloader"></div> */}
      <div id="wrapper">

        <header>
          <div className="container">
            <span id="menu-btn"></span>
            <div className="row" id="row">
              <div className="col-md-3">

                <div id="logo">
                  <div className="inner">
                    <a href="http://mytft.co.za/">
                      <img src="img/logo.png" alt="" className="logo-1" />
                      <img src="img/logo-2.png" alt="" className="logo-2" />
                    </a>

                  </div>
                </div>

              </div>

              <div className="col-md-9">

                <div id="mainmenu-container">
                  <ul id="mainmenu">
                    <li><a href="http://mytft.co.za">Home</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section id="subheader" data-speed="2" data-type="background">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Register</h1>
              </div>
            </div>
          </div>
        </section>

        <div id="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <div id="blog-comment">
                  <h2><span>Please register with us</span></h2>
                  <br />
                  <div id="comment-form-wrapper">
                    <h3>Register now</h3>
                    <div className="comment_form_holder">
                      <form id="commentform" name="form1" method="post" onSubmit={handleSubmit}

                      >
                        <div className="row">
                          <div className="col-md-6">
                            <label>First Name</label>
                            <input type="text" name="first_name" id="first_name" className="form-control"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Last Name</label>
                            <input type="text" name="last_name" id="last_name" className="form-control"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Phone Number</label>
                            <input type="text" name="phone_number" id="phone_number" className="form-control"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Address</label>
                            <input type="text" name="address" id="address" className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                          {/* 
                          <div className="col-md-6">
                            <label>Department</label>
                            <select multiple name="department" id="department" className="form-control"
                              onChange={handleChange}
                            >
                              <option value="none">None</option>
                              <option value="adoration"> Adoration</option>
                              <option value="advisory">Advisory</option>
                              <option value="bible_class">Bible Class</option>
                              <option value="bible_college">Bible College</option>
                              <option value="carees_department">Careers Department</option>
                              <option value="childrens_church">Children's Church </option>
                              <option value="creative_arts">Creative Arts </option>
                              <option value="dance">Dance </option>
                              <option value="defence">Defence </option>
                              <option value="evangelism_and_mission">Evangelism And Mission </option>
                              <option value="health_safety_and_security">Health Safety And Security </option>
                              <option value="helping_hand">Helping Hand </option>
                              <option value="home_affiars">Home Affairs </option>
                              <option value="home_fellowship">Home Fellowship </option>
                              <option value="ladies_fellowship">Home Fellowship </option>
                              <option value="marriage_workshop">Marriage Workshop </option>
                              <option value="office_and_administration">Office Addministration </option>
                              <option value="pastrol">Pastrol </option>
                              <option value="public_ralations">Public Relations </option>
                              <option value="senior_citizen">Senior Citizen </option>
                              <option value="sports_and_recreations">Spots And Recreations </option>
                              <option value="teens_church">Teen's Church </option>
                              <option value="ushering">Ushering </option>
                              <option value="youth_and_young_adult">Youth And Young Adult </option>
                            </select>
                          </div>
 */}

                          <div className="col-md-6">
                            <div className="multiselect">
                              <div className="selectBox" id="selectBox" onClick={showCheckboxes}>
                                <label>Department</label>
                                <select name="department" id="department" className="form-control">
                                  <option>Choose Department</option>
                                </select>
                                <div className="overSelect"></div>
                              </div>

                              <div id="checkboxes">
                                {departmentList && departmentList.map((dept, i) => (
                                  <div key={i}>
                                    <input type="checkbox" value={dept.id} id={dept.id} className="checkbox-input" onChange={chooseDepartment} />
                                    <label className="checkbox-label" htmlFor={dept.id}>{dept.name}</label>
                                  </div>
                                ))}

                                <div>
                                  <input type="checkbox" value="one" id="one" className="checkbox-input" onChange={chooseDepartment} />
                                  <label className="checkbox-label" for="one">First checkbox</label>
                                </div>

                                <div>
                                  <input type="checkbox" value="two" id="two" className="checkbox-input" onChange={chooseDepartment} />
                                  <label className="checkbox-label" for="two">Second checkbox</label>
                                </div>

                                <div>
                                  <input type="checkbox" value="three" id="three" className="checkbox-input" onChange={chooseDepartment} />
                                  <label className="checkbox-label" for="three">Third checkbox</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="birth_year">Year of Birth</label>
                            <input type="number" name="birth_year" id="birth_year" className="form-control"
                              onChange={handleChange} placeholder="1983"
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="dependents">Dependents Under (18-) Attending TFT?</label>
                            <select name="dependents" id="dependents" className="form-control"
                              onChange={handleChange}
                            >
                              <option value="no">No</option>
                              <option value="yes">Yes</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="dependent_list">If Yes Above, List them (First Name & Last Name). <br /> (Comma seperate list names)</label>
                            <input type="text" name="dependent_list" id="dependent_list" className="form-control"
                              onChange={handleChange} placeholder="John Doe, Mosekimang Sebulediso"
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="membership_year">Member Since? (year)</label>
                            <input type="number" name="membership_year" id="membership_year" className="form-control"
                              onChange={handleChange} placeholder="2001"
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="gender">Gender</label>
                            <select name="gender" id="gender" className="form-control"
                              onChange={handleChange} required
                            >
                              <option value={null}>Select Gender</option>
                              <option value={"female"}>Female</option>
                              <option value={"male"}>Male</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="merital_status">Marital Status</label>
                            <select name="merital_status" id="merital_status" className="form-control"
                              onChange={handleChange} required
                            >
                              <option value={null}>Marital Status</option>
                              <option value={"single"}>Single</option>
                              <option value={"married"}>Married</option>
                              <option value={"widowed"}>Widowed</option>
                              <option value={"devorced"}>Devorced</option>
                            </select>
                          </div>


                          <div className="col-md-6">
                            <label htmlFor="spouse_member">If Married, Is your spouse a member of TFT?</label>
                            <select name="spouse_member" id="spouse_member" className="form-control"
                              onChange={handleChange} required
                            >
                              <option value={null}>Select</option>
                              <option value={"yes"}>Yes</option>
                              <option value={"no"}>No</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label>Email <span className="req">*</span></label>
                            <input type="email" name="email" id="email" className="form-control" required
                              onChange={handleChange}
                            />
                            <div id="error_email" className="error">Please check your email</div>
                          </div>

                          <div className="col-md-6">
                            <label>Password <span className="req">*</span></label>
                            <input type="password" name="password" id="password" className="form-control" required
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div id="error_message" className="error">Please check your message</div>
                        <div id="mail_success" className="success">Thank you. Your message has been sent.</div>
                        <div id="mail_failed" className="error">Error, email not sent</div>

                        {loading && (<Loader />)}

                        {error.message && (
                          <AlertComponent message={error.message} />
                        )}

                        {success.message && (
                          <SuccessComponent message={success.message} />
                        )}

                        {!loading && !success.message && (
                          <p id="btnsubmit" style={{ marginTop: '16px' }}>
                            <input type="submit" id="send" value="Register" className="btn btn-custom" />
                          </p>
                        )}

                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
