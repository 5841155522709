import React from 'react';
import './AlertComponent.css';

function AlertComponent(props) {

	return (
		<div className={"alert alert-danger alert-dismissable mt-4"}
			role="alert"
		>
			<p>{props.message}</p>
		</div>
	)
}

export default AlertComponent